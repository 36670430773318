import { useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { NBAPrediction } from '../../../types/nbaPredictions';
import { NBAGame } from '../../../types/nba';
import { OddsFormat } from '../../../types/odds';
import { Bookmaker, getUserBookmakers } from '../../../services/bookmakerService';
import { useAuth } from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import { trackBet, getOddsTypes, getBetLegTypes, getSingleBetTypeId } from '../../../services/betTrackingService';
import { CreateBetData, OddsType, BetLegType } from '../../../types/betting';
import { supabase } from '../../../lib/supabase/client';
import { useToast } from '../../../contexts/ToastContext';
import { getCurrencyConfig } from '../../../utils/currencyFormatter';
import locationService from '../../../utils/locationService';

interface BettingModalProps {
  isOpen: boolean;
  onClose: () => void;
  game: NBAPrediction | NBAGame;
  eventId?: string;
  oddsFormat: OddsFormat;
}

// NBA specific IDs
const NBA_SPORT_ID = '3bd80add-015f-4605-8465-9603f79ab951';
const NBA_LEAGUE_ID = '615abc50-d0ad-4c6e-b396-43a546419e24';
const PENDING_STATUS_ID = '986ae62d-e908-4163-8186-6655755cd53d';

// Type guard to check if game is NBAPrediction
const isNBAPrediction = (game: NBAPrediction | NBAGame): game is NBAPrediction => {
  return 'prediction' in game && 'teams' in game;
};

interface GameTeams {
  awayTeam: string;
  homeTeam: string;
  winner?: string;
  winProbability?: number;
}

const getGameTeams = (game: NBAPrediction | NBAGame): GameTeams => {
  if (isNBAPrediction(game)) {
    return {
      awayTeam: game.teams.away.name,
      homeTeam: game.teams.home.name,
      winner: game.prediction.winner,
      winProbability: game.prediction.win_probability
    };
  }
  return {
    awayTeam: game.awayTeam.teamName,
    homeTeam: game.homeTeam.teamName,
    winner: game.gameStatusText.includes('Final') 
      ? (game.awayTeam.score > game.homeTeam.score ? game.awayTeam.teamName : game.homeTeam.teamName)
      : undefined
  };
};

const BettingModal = ({ isOpen, onClose, game, eventId, oddsFormat }: BettingModalProps) => {
  const [stake, setStake] = useState('');
  const [odds, setOdds] = useState('');
  const [userBookmakers, setUserBookmakers] = useState<Bookmaker[]>([]);
  const [selectedBookmaker, setSelectedBookmaker] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [oddsTypes, setOddsTypes] = useState<OddsType[]>([]);
  const [legTypes, setLegTypes] = useState<BetLegType[]>([]);
  const [legTypeId, setLegTypeId] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string>('US');
  const { user } = useAuth();
  const { showToast } = useToast();

  useEffect(() => {
    const loadData = async () => {
      if (!user?.id) return;
      
      setLoading(true);
      try {
        // Load initial data
        const [bookmakers, oddsTypesData, legTypesData, location] = await Promise.all([
          getUserBookmakers(user.id),
          getOddsTypes(),
          getBetLegTypes(),
          locationService.getCountryCode()
        ]);
        
        setUserBookmakers(bookmakers);
        setOddsTypes(oddsTypesData);
        setLegTypes(legTypesData);
        setCountryCode(location);

        if (eventId) {
          // Get bet leg type ID based on moneyline (default type)
          const { data: legTypeData, error: legTypeError } = await supabase
            .from('bet_leg_types')
            .select('id')
            .eq('name', 'moneyline')
            .single();

          if (legTypeError) throw legTypeError;
          if (!legTypeData) throw new Error('Bet leg type not found');

          setLegTypeId(legTypeData.id);
        }
        
        setSelectedBookmaker('');
      } catch (error) {
        console.error('Error loading data:', error);
        setError('Failed to load bet data');
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      loadData();
      setStake('');
      setOdds('');
      setError(null);
    }
  }, [isOpen, user?.id, eventId]);

  const handleStakeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setStake(value);
    }
  };

  const handleOddsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    switch (oddsFormat) {
      case 'american':
        if (/^-?\d*$/.test(value)) {
          setOdds(value);
        }
        break;
      case 'decimal':
        if (/^\d*\.?\d{0,2}$/.test(value)) {
          setOdds(value);
        }
        break;
      case 'fractional':
        if (/^\d*\/?\.?\d*$/.test(value)) {
          setOdds(value);
        }
        break;
    }
  };

  const getOddsPlaceholder = () => {
    switch (oddsFormat) {
      case 'american':
        return 'Enter odds (e.g. -110, +150)';
      case 'decimal':
        return 'Enter odds (e.g. 1.91, 2.50)';
      case 'fractional':
        return 'Enter odds (e.g. 10/1, 5/2)';
    }
  };

  const handlePlaceBet = async () => {
    try {
      setError(null);
      if (!user?.id || !stake || !odds || !selectedBookmaker || !legTypeId || !eventId) {
        setError('Missing required fields');
        return;
      }
  
      const oddsType = oddsTypes.find(type => type.name.toLowerCase() === oddsFormat);
      if (!oddsType) {
        setError('Invalid odds format');
        return;
      }
  
      // First get the prediction UUID using the numeric event_id
      const { data: predictionData, error: predictionError } = await supabase
        .from('predictions')
        .select('id')
        .eq('event_id', eventId)
        .single();
  
      if (predictionError || !predictionData) {
        console.error('Error fetching prediction:', predictionError);
        setError('Failed to fetch prediction data');
        return;
      }
  
      const singleBetTypeId = await getSingleBetTypeId();
      const teams = getGameTeams(game);
  
      if (!teams.winner) {
        setError('No winner prediction available');
        return;
      }
  
      const betData: CreateBetData = {
        bookmaker_id: selectedBookmaker,
        bet_type_id: singleBetTypeId,
        bet_status_id: PENDING_STATUS_ID,
        stake: parseFloat(stake),
        odds_type_id: oddsType.id,
        odds: odds,
        legs: [{
          sport_id: NBA_SPORT_ID,
          league_id: NBA_LEAGUE_ID,
          event_name: `${teams.awayTeam} @ ${teams.homeTeam}`,
          selection: teams.winner,
          event_start: new Date().toISOString(),
          odds_type_id: oddsType.id,
          odds: odds,
          event_id: eventId, // This stays as the numeric event ID
          leg_type_id: legTypeId,
          bet_status_id: PENDING_STATUS_ID,
          prediction_id: predictionData.id // This is the UUID
        }]
      };
  
      const result = await trackBet(betData);
      const currencyConfig = getCurrencyConfig(countryCode);
      const notificationMessage = `New bet placed: ${teams.winner} (${teams.awayTeam} @ ${teams.homeTeam}) - ${currencyConfig.symbol}${stake}`;
  
      await supabase.from('notifications').insert([{
        user_id: user.id,
        message: notificationMessage,
        type: 'bet_update',
        metadata: {
          betId: result.bet.id,
          stake: parseFloat(stake),
          selection: teams.winner,
          url: '/dashboard/bet-tracker',
          status: 'pending'
        }
      }]);
  
      showToast(notificationMessage, 'success');
      onClose();
  
    } catch (err) {
      console.error('Error placing bet:', err);
      setError('Failed to place bet');
      showToast('Failed to place bet', 'error');
    }
  };

  if (!isOpen) return null;

  const teams = getGameTeams(game);
  const currencyConfig = getCurrencyConfig(countryCode);

  const modalContent = (
    <div 
      className="fixed inset-0 flex items-center justify-center z-[9999]"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.75)' }}
    >
      <div className="bg-[#1A1A23] rounded-xl p-6 w-full max-w-xl mx-4 relative">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-white text-xl font-semibold">Place Single Bet</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-white">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-5">
          <div className="text-white">
            <p className="mb-2 text-gray-400">Game:</p>
            <p className="text-lg font-medium">{teams.awayTeam} @ {teams.homeTeam}</p>
          </div>

          {teams.winner && (
            <div className="text-white">
              <p className="mb-2 text-gray-400">Prediction:</p>
              <p className="text-lg font-medium text-[#4263EB]">{teams.winner} to Win</p>
              {teams.winProbability !== undefined && (
                <p className="text-sm text-gray-400 mt-1">
                  {teams.winProbability.toFixed(1)}% Win Probability
                </p>
              )}
            </div>
          )}

          {loading ? (
            <div className="text-white text-center py-4">Loading bookmakers...</div>
          ) : (
            <>
              {userBookmakers.length === 0 ? (
                <div className="bg-[#2A2A35] rounded-lg p-4 text-white">
                  <p className="mb-2">No bookmakers selected</p>
                  <p className="text-sm text-gray-400 mb-3">
                    You need to select bookmakers before placing bets.
                  </p>
                  <Link
                    to="/bookmakers"
                    className="text-[#4263EB] hover:text-[#3651C9] transition-colors"
                  >
                    Go to Bookmakers to Select Bookmakers →
                  </Link>
                </div>
              ) : (
                <div className="text-white">
                  <label htmlFor="bookmaker" className="block mb-2 text-gray-400">Select Bookmaker:</label>
                  <select
                    id="bookmaker"
                    value={selectedBookmaker}
                    onChange={(e) => setSelectedBookmaker(e.target.value)}
                    className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB] appearance-none cursor-pointer"
                  >
                    <option value="">Select a bookmaker</option>
                    {userBookmakers.map((bookmaker) => (
                      <option key={bookmaker.id} value={bookmaker.id}>
                        {bookmaker.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <div className="space-y-4">
                <div className="text-white">
                  <label htmlFor="stake" className="block mb-2 text-gray-400">
                    Stake {currencyConfig.position === 'before' ? currencyConfig.symbol : ''}
                  </label>
                  <div className="relative">
                    <input
                      id="stake"
                      type="text"
                      value={stake}
                      onChange={handleStakeChange}
                      placeholder={`Enter stake amount${currencyConfig.position === 'after' ? ' ' + currencyConfig.symbol : ''}`}
                      className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB]"
                    />
                    {currencyConfig.position === 'after' && (
                      <span className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400">
                        {currencyConfig.symbol}
                      </span>
                    )}
                  </div>
                </div>

                <div className="text-white">
                  <label htmlFor="odds" className="block mb-2 text-gray-400">
                    Odds ({oddsFormat.charAt(0).toUpperCase() + oddsFormat.slice(1)})
                  </label>
                  <input
                    id="odds"
                    type="text"
                    value={odds}
                    onChange={handleOddsChange}
                    placeholder={getOddsPlaceholder()}
                    className="w-full bg-[#2A2A35] text-white py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#4263EB]"
                  />
                </div>
              </div>

              {error && (
                <div className="text-red-500 text-sm mt-2">
                  {error}
                </div>
              )}

              <button
                className="w-full bg-[#4263EB] text-white py-3 px-4 rounded-lg font-medium hover:bg-[#3651C9] transition-colors disabled:opacity-50 disabled:cursor-not-allowed mt-6"
                onClick={handlePlaceBet}
                disabled={!stake || !odds || !selectedBookmaker || !legTypeId}
              >
                Place Single Bet
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return createPortal(modalContent, document.body);
};

export default BettingModal;