import React, { useState, useEffect } from 'react';
import { NBAPrediction, NBAPredictionsResponse } from '../../types/nbaPredictions';
import { fetchNBAPredictions } from '../../services/nbaPredictionsService';
import { useAuth } from '../../hooks/useAuth';
import { supabase } from '../../lib/supabase/client';

interface Bet {
  type: 'winner';
  confidence: number;
  game: NBAPrediction;
  description: string;
}

const ParlayGenerator: React.FC = () => {
  const [predictions, setPredictions] = useState<NBAPredictionsResponse | null>(null);
  const [parlay, setParlay] = useState<Bet[]>([]);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  useEffect(() => {
    const loadPredictions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNBAPredictions(session.access_token);
          setPredictions(data);
        }
      } catch (err) {
        setError('Failed to load predictions');
        console.error(err);
      }
    };

    if (user) {
      loadPredictions();
    }
  }, [user]);

  const generateParlay = (): void => {
    if (!predictions?.games) return;

    const allBets: Bet[] = predictions.games.map((game) => ({
      type: 'winner',
      confidence: game.prediction.win_probability,
      game: game,
      description: `${game.prediction.winner} to win`
    }));

    // Sort bets by confidence
    const sortedBets = allBets.sort((a, b) => b.confidence - a.confidence);

    // Take the top 3 most confident picks
    setParlay(sortedBets.slice(0, 3));
  };

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
      </div>
    );
  }

  if (!predictions) {
    return (
      <div className="text-white p-4">
        Loading predictions...
      </div>
    );
  }

  return (
    <div className="p-6 bg-[#13131A]">
      <h1 className="text-2xl font-bold text-white mb-6">NBA Parlay Generator</h1>
      
      <button 
        onClick={generateParlay}
        className="px-6 py-3 bg-[#696cff] text-white rounded-md hover:bg-[#6062eb] transition-colors duration-200"
      >
        Generate Best 3-Leg Parlay
      </button>

      {parlay.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-semibold text-white mb-4">Recommended Parlay:</h2>
          <div className="space-y-4">
            {parlay.map((bet, index) => (
              <div 
                key={index} 
                className="p-4 bg-[#1E1E26] rounded-lg border border-[#474E72]"
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-[#00F6FF] font-medium">Leg {index + 1}</span>
                  <span className="text-green-400">
                    {bet.confidence.toFixed(1)}% Confidence
                  </span>
                </div>

                <div className="text-white">{bet.description}</div>

                <div className="mt-2 text-sm text-gray-400">
                  <div className="flex justify-between">
                    <span>Team Health:</span>
                    <div>
                      Away: {bet.game.team_health.away.toFixed(1)}% | 
                      Home: {bet.game.team_health.home.toFixed(1)}%
                    </div>
                  </div>
                  <div className="flex justify-between mt-1">
                    <span>Recent Form:</span>
                    <div>
                      Away: {bet.game.recent_form.away} | 
                      Home: {bet.game.recent_form.home}
                    </div>
                  </div>
                  <div className="flex justify-between mt-1">
                    <span>Spread Confidence:</span>
                    <div>
                      {bet.game.prediction.spread_confidence_interval.low.toFixed(1)} to {bet.game.prediction.spread_confidence_interval.high.toFixed(1)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ParlayGenerator;
