import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ResizableBox } from 'react-resizable';
import { NBAPropsResponse, NBAPlayerPrediction } from '../../../types/nbaPredictions';
import { fetchNBAProps } from '../../../services/nbaPredictionsService';
import { useAuth } from '../../../hooks/useAuth';
import { useInjuries } from '../../../contexts/InjuryContext';
import { supabase } from '../../../lib/supabase/client';

type ViewMode = 'card' | 'list';
type Position = 'ALL' | 'G' | 'F' | 'C';
type SortField = 'PTS' | 'AST' | 'REB' | 'BLK' | 'STL';
type SortDirection = 'asc' | 'desc';

const HEADER_HEIGHT = 64;
const SCROLL_THRESHOLD = 100;

const normalizePlayerName = (name: string): string => {
  return name
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-z]/g, '');
};

const NBAProps = () => {
  const [props, setProps] = useState<NBAPropsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [viewMode, setViewMode] = useState<ViewMode>('list');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPosition, setSelectedPosition] = useState<Position>('ALL');
  const [sortField, setSortField] = useState<SortField>('PTS');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const [height, setHeight] = useState(200);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const { user } = useAuth();
  const { injuries } = useInjuries();

  const filterOutInjuredPlayers = (data: NBAPropsResponse): NBAPropsResponse => {
    if (!data) return {};

    const injuredPlayerNames = injuries
      .filter(injury => injury.status === 'Out')
      .map(injury => normalizePlayerName(injury.player));

    return Object.entries(data).reduce((acc, [key, player]) => {
      if (!injuredPlayerNames.includes(normalizePlayerName(player.PLAYER))) {
        acc[key] = player;
      }
      return acc;
    }, {} as NBAPropsResponse);
  };

  const handleScroll = () => {
    if (contentRef.current) {
      setShowBackToTop(contentRef.current.scrollTop > SCROLL_THRESHOLD);
    }
  };

  const scrollToTop = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const loadProps = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNBAProps(session.access_token);
          const filteredData = filterOutInjuredPlayers(data);
          setProps(filteredData);
        }
      } catch (err) {
        setError('Failed to load player props');
        console.error(err);
      }
    };

    if (user) {
      loadProps();
    }
  }, [user, injuries]);

  useEffect(() => {
    const scoreboardContainer = document.querySelector('.rounded-2xl.bg-\\[\\#13131A\\].backdrop-blur-sm.border.border-\\[\\#2E3449\\].overflow-hidden') as HTMLElement;
    
    if (scoreboardContainer) {
      const updateHeight = () => {
        setHeight(scoreboardContainer.offsetHeight);
      };

      updateHeight();
      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(scoreboardContainer);
      return () => resizeObserver.disconnect();
    }
  }, []);

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  if (!props) {
    return <div className="text-white p-4">Loading player props...</div>;
  }

  const handleSort = (field: SortField) => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
  };

  const getSortIcon = (field: SortField) => {
    if (sortField !== field) return '↕️';
    return sortDirection === 'asc' ? '↑' : '↓';
  };

  const filteredPlayers = Object.values(props)
    .filter(player => {
      if (!player.predictions) return false;
      
      const query = searchQuery.toLowerCase();
      const matchesSearch = 
        player.PLAYER.toLowerCase().includes(query) || 
        (player.TEAM?.toLowerCase().includes(query) ?? false);
      const matchesPosition = selectedPosition === 'ALL' || player.POSITION.includes(selectedPosition);
      
      return matchesSearch && matchesPosition;
    })
    .sort((a, b) => {
      const valueA = a.predictions[sortField];
      const valueB = b.predictions[sortField];
      return sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
    });

  const renderCardView = (player: NBAPlayerPrediction) => (
    <div className="bg-[#13131A] rounded-xl overflow-hidden h-full flex flex-col">
      <div className="p-4 border-b border-[rgba(255,255,255,0.05)]">
        <div className="text-center">
          <Link 
            to={`/dashboard/nba/player?player=${encodeURIComponent(player.PLAYER)}`}
            className="text-white hover:text-[#00F6FF] transition-colors"
          >
            <h4 className="font-semibold mb-2">{player.PLAYER}</h4>
          </Link>
          <div className="flex justify-center mb-4">
            <img 
              src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${player.PLAYER_ID}.png`}
              alt={player.PLAYER}
              className="w-20 h-20 rounded-full object-cover"
            />
          </div>
          <p className="text-[#8F9BB3] text-sm">{player.POSITION}</p>
          {player.TEAM && <p className="text-[#8F9BB3] text-sm">{player.TEAM}</p>}
        </div>
      </div>
      <div className="p-4 flex-1">
        <div className="text-center h-full">
          <p className="text-[#8F9BB3] text-sm mb-3">Predictions:</p>
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead>
                <tr className="text-[#8F9BB3]">
                  <th className="px-2 py-1 cursor-pointer hover:text-[#00F6FF]" onClick={() => handleSort('PTS')}>
                    PTS {getSortIcon('PTS')}
                  </th>
                  <th className="px-2 py-1 cursor-pointer hover:text-[#00F6FF]" onClick={() => handleSort('AST')}>
                    AST {getSortIcon('AST')}
                  </th>
                  <th className="px-2 py-1 cursor-pointer hover:text-[#00F6FF]" onClick={() => handleSort('REB')}>
                    REB {getSortIcon('REB')}
                  </th>
                  <th className="px-2 py-1 cursor-pointer hover:text-[#00F6FF]" onClick={() => handleSort('BLK')}>
                    BLK {getSortIcon('BLK')}
                  </th>
                  <th className="px-2 py-1 cursor-pointer hover:text-[#00F6FF]" onClick={() => handleSort('STL')}>
                    STL {getSortIcon('STL')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-white">
                  <td className="px-2 py-1">{Math.round(player.predictions.PTS)}</td>
                  <td className="px-2 py-1">{Math.round(player.predictions.AST)}</td>
                  <td className="px-2 py-1">{Math.round(player.predictions.REB)}</td>
                  <td className="px-2 py-1">{Math.round(player.predictions.BLK)}</td>
                  <td className="px-2 py-1">{Math.round(player.predictions.STL)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );

  const renderListView = (player: NBAPlayerPrediction) => (
    <div className="bg-[#13131A] rounded-xl overflow-hidden p-4 flex items-center gap-4">
      <img 
        src={`https://cdn.nba.com/headshots/nba/latest/1040x760/${player.PLAYER_ID}.png`}
        alt={player.PLAYER}
        className="w-12 h-12 rounded-full object-cover"
      />
      <div className="flex-1">
        <Link 
          to={`/dashboard/nba/player?player=${encodeURIComponent(player.PLAYER)}`}
          className="text-white hover:text-[#00F6FF] transition-colors"
        >
          <h4 className="font-semibold">{player.PLAYER}</h4>
        </Link>
        <p className="text-[#8F9BB3] text-sm">{player.POSITION}</p>
        {player.TEAM && <p className="text-[#8F9BB3] text-sm">{player.TEAM}</p>}
      </div>
      <div className="flex gap-4 text-white text-sm">
        <div className="text-center cursor-pointer" onClick={() => handleSort('PTS')}>
          <p className="text-[#8F9BB3]">PTS {getSortIcon('PTS')}</p>
          <p>{Math.round(player.predictions.PTS)}</p>
        </div>
        <div className="text-center cursor-pointer" onClick={() => handleSort('AST')}>
          <p className="text-[#8F9BB3]">AST {getSortIcon('AST')}</p>
          <p>{Math.round(player.predictions.AST)}</p>
        </div>
        <div className="text-center cursor-pointer" onClick={() => handleSort('REB')}>
          <p className="text-[#8F9BB3]">REB {getSortIcon('REB')}</p>
          <p>{Math.round(player.predictions.REB)}</p>
        </div>
        <div className="text-center cursor-pointer" onClick={() => handleSort('BLK')}>
          <p className="text-[#8F9BB3]">BLK {getSortIcon('BLK')}</p>
          <p>{Math.round(player.predictions.BLK)}</p>
        </div>
        <div className="text-center cursor-pointer" onClick={() => handleSort('STL')}>
          <p className="text-[#8F9BB3]">STL {getSortIcon('STL')}</p>
          <p>{Math.round(player.predictions.STL)}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="space-y-4">
      {/* Controls */}
      <div className="sticky top-0 z-10 bg-[#13131A] pb-4">
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="flex-1 w-full">
            <input
              type="text"
              placeholder="Search players or teams..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full px-4 py-2 bg-[#13131A] border border-[rgba(255,255,255,0.1)] rounded-lg text-white placeholder-[#8F9BB3] focus:outline-none focus:border-[#00F6FF]"
            />
          </div>
          <div className="flex gap-2">
            <select
              value={selectedPosition}
              onChange={(e) => setSelectedPosition(e.target.value as Position)}
              className="px-4 py-2 bg-[#13131A] border border-[rgba(255,255,255,0.1)] rounded-lg text-white focus:outline-none focus:border-[#00F6FF]"
            >
              <option value="ALL">All Positions</option>
              <option value="G">Guards</option>
              <option value="F">Forwards</option>
              <option value="C">Centers</option>
            </select>
            <div className="flex bg-[#13131A] border border-[rgba(255,255,255,0.1)] rounded-lg overflow-hidden">
              <button
                onClick={() => setViewMode('card')}
                className={`px-4 py-2 ${viewMode === 'card' ? 'bg-[#4263EB] text-white' : 'text-[#8F9BB3] hover:text-white'}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
              </button>
              <button
                onClick={() => setViewMode('list')}
                className={`px-4 py-2 ${viewMode === 'list' ? 'bg-[#4263EB] text-white' : 'text-[#8F9BB3] hover:text-white'}`}
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Resizable Container */}
      <ResizableBox
        width={Infinity}
        height={height}
        minConstraints={[Infinity, 200]}
        onResize={(e, { size }) => {
          setHeight(size.height);
        }}
        handle={
          <div className="absolute -bottom-3 left-0 right-0 h-6 cursor-ns-resize flex justify-center items-center hover:cursor-ns-resize">
            <div className="w-10 h-0.5 bg-white/20 rounded hover:bg-white/40 transition-colors" />
          </div>
        }
        className="relative w-full bg-white/3 rounded-2xl border border-white/5"
        axis="y"
      >
        <div 
          ref={contentRef}
          className="h-full overflow-auto scrollbar-thin scrollbar-thumb-[#2E3449] scrollbar-track-transparent"
          onScroll={handleScroll}
        >
          <div className="p-4">
            <div className={viewMode === 'card' ? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4' : 'space-y-4'}>
              {filteredPlayers.map((player, index) => (
                <div key={index} className="min-w-0">
                  {viewMode === 'card' ? renderCardView(player) : renderListView(player)}
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Scroll To Top Button */}
        {showBackToTop && (
          <button
            onClick={scrollToTop}
            className="absolute bottom-4 right-4 bg-[#4263EB] text-white p-2 rounded-full shadow-lg hover:bg-[#3651C9] transition-colors"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clipRule="evenodd" />
            </svg>
          </button>
        )}
      </ResizableBox>
    </div>
  );
};

export default NBAProps;