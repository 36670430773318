import { createContext, useContext, useState, useEffect } from 'react';
import { Notification, notificationService } from '../services/notificationService';
import { useAuth } from '../hooks/useAuth';
import { RealtimeChannel } from '@supabase/supabase-js';
import { supabase } from '../lib/supabase/client';
import { useToast } from '../contexts/ToastContext';

interface NotificationContextType {
  notifications: Notification[];
  unreadCount: number;
  markAsRead: (id: string) => Promise<void>;
  markAllAsRead: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { user } = useAuth();
  const [channel, setChannel] = useState<RealtimeChannel | null>(null);
  const { showToast } = useToast();

  useEffect(() => {
    if (!user?.id) {
      setNotifications([]);
      setUnreadCount(0);
      return;
    }

    // Load initial notifications
    const loadNotifications = async () => {
      try {
        const notifs = await notificationService.getNotifications(user.id);
        console.log('Fetched notifications:', notifs);
        setNotifications(notifs);
        setUnreadCount(notifs.filter(n => !n.read).length);
        console.log('Set unread count:', notifs.filter(n => !n.read).length);
      } catch (error) {
        console.error('Error loading notifications:', error);
      }
    };

    loadNotifications();

    // Set up real-time subscription
    const newChannel = supabase
      .channel(`notifications-${user.id}`)
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        },
        (payload) => {
          const newNotification = payload.new as Notification;
          console.log('New notification received:', newNotification);
          
          // Add to notifications state
          setNotifications(prev => [newNotification, ...prev]);
          setUnreadCount(prev => prev + 1);
          
          // Show toast based on notification type and status
          const toastType = newNotification.type === 'bet_result' 
            ? (newNotification.metadata?.status === 'won' ? 'success' : 'error')
            : 'info';
          
          // Only show toast for bet updates and results
          if (newNotification.type === 'bet_update' || newNotification.type === 'bet_result') {
            showToast(newNotification.message, toastType);
          }
        }
      )
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id}`
        },
        (payload) => {
          const updatedNotification = payload.new as Notification;
          console.log('Notification updated:', updatedNotification);
          
          // Update notifications state
          setNotifications(prev =>
            prev.map(n => n.id === updatedNotification.id ? updatedNotification : n)
          );
          
          // Show toast for status updates (e.g., bet settled)
          if (updatedNotification.type === 'bet_result') {
            const toastType = updatedNotification.metadata?.status === 'won' ? 'success' : 'error';
            showToast(updatedNotification.message, toastType);
          }
          
          updateUnreadCount();
        }
      )
      .subscribe();

    setChannel(newChannel);

    return () => {
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user?.id, showToast]);

  const updateUnreadCount = () => {
    const count = notifications.filter(n => !n.read).length;
    console.log('Updating unread count:', count);
    setUnreadCount(count);
  };

  const markAsRead = async (id: string) => {
    await notificationService.markAsRead(id);
    setNotifications(prev =>
      prev.map(n => n.id === id ? { ...n, read: true } : n)
    );
    updateUnreadCount();
  };

  const markAllAsRead = async () => {
    if (!user?.id) return;
    await notificationService.markAllAsRead(user.id);
    setNotifications(prev =>
      prev.map(n => ({ ...n, read: true }))
    );
    setUnreadCount(0);
  };

  return (
    <NotificationContext.Provider value={{
      notifications,
      unreadCount,
      markAsRead,
      markAllAsRead
    }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};