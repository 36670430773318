import React from 'react';
import { NBAPrediction } from '../../../types/nbaPredictions';

interface NBAGameModalProps {
  game: NBAPrediction;
  isOpen: boolean;
  onClose: () => void;
}

const NBAGameModal: React.FC<NBAGameModalProps> = ({ game, isOpen, onClose }) => {
  if (!isOpen) return null;

  const formatPercentage = (value: number) => `${value.toFixed(1)}%`;

  const TeamLineup = ({ team, isHome }: { team: typeof game.teams.home | typeof game.teams.away, isHome: boolean }) => (
    <div className="flex-1 backdrop-blur-sm bg-[#1A1A23]/50 rounded-2xl p-6">
      <div className="flex items-center justify-center gap-4 mb-8">
        <img
          src={`/assets/img/nba-logos/${team.name.toLowerCase().replace(/\s+/g, '')}.png`}
          alt={team.name}
          className="w-16 h-16 object-contain"
        />
        <div>
          <h3 className="text-2xl font-bold text-white">{team.name}</h3>
          <span className="text-[#8F9BB3]">{isHome ? 'Home Team' : 'Away Team'}</span>
        </div>
      </div>
      
      <div className="space-y-8">
        {/* Starters Section */}
        <div>
          <div className="flex items-center gap-2 mb-4">
            <div className="w-2 h-2 rounded-full bg-[#00F6FF]" />
            <h4 className="text-xl font-semibold text-white">Starting Lineup</h4>
          </div>
          <div className="space-y-2">
            {team.lineups.starters.map((player, index) => (
              <div 
                key={index} 
                className="bg-[#13131A]/80 rounded-xl p-4 hover:bg-[#13131A] transition-colors duration-200"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-white font-medium">{player.name}</span>
                    <span className="text-[#8F9BB3] ml-2 text-sm">({player.position})</span>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="px-3 py-1 rounded-full bg-[#00F6FF]/10 text-[#00F6FF] text-sm">
                      {player.impact_score.toFixed(2)} Impact
                    </div>
                    <span className="text-[#8F9BB3] text-sm">{player.avg_minutes.toFixed(1)} min</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Bench Section */}
        <div>
          <div className="flex items-center gap-2 mb-4">
            <div className="w-2 h-2 rounded-full bg-[#FFD426]" />
            <h4 className="text-xl font-semibold text-white">Bench</h4>
          </div>
          <div className="space-y-2">
            {team.lineups.bench.map((player, index) => (
              <div 
                key={index} 
                className="bg-[#13131A]/80 rounded-xl p-4 hover:bg-[#13131A] transition-colors duration-200"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <span className="text-white font-medium">{player.name}</span>
                    <span className="text-[#8F9BB3] ml-2 text-sm">({player.position})</span>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="px-3 py-1 rounded-full bg-[#FFD426]/10 text-[#FFD426] text-sm">
                      {player.impact_score.toFixed(2)} Impact
                    </div>
                    <span className="text-[#8F9BB3] text-sm">{player.avg_minutes.toFixed(1)} min</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Team Stats */}
        <div>
          <div className="flex items-center gap-2 mb-4">
            <div className="w-2 h-2 rounded-full bg-[#4ADE80]" />
            <h4 className="text-xl font-semibold text-white">Team Stats</h4>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-[#13131A]/80 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-2">
                <svg className="w-4 h-4 text-[#4ADE80]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                </svg>
                <span className="text-[#8F9BB3]">Team Health</span>
              </div>
              <div className="text-2xl font-bold text-white">
                {isHome ? formatPercentage(game.team_health.home) : formatPercentage(game.team_health.away)}
              </div>
            </div>
            <div className="bg-[#13131A]/80 rounded-xl p-4">
              <div className="flex items-center gap-2 mb-2">
                <svg className="w-4 h-4 text-[#4ADE80]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
                </svg>
                <span className="text-[#8F9BB3]">Recent Form</span>
              </div>
              <div className="text-2xl font-bold text-white">
                {isHome ? game.recent_form.home : game.recent_form.away}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="fixed inset-0 bg-black/95 z-50 overflow-y-auto backdrop-blur-sm">
      <div className="min-h-screen bg-gradient-to-b from-[#13131A] to-black/50 p-8">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <div>
            <h2 className="text-3xl font-bold bg-gradient-to-r from-[#00F6FF] to-[#4ADE80] bg-clip-text text-transparent">
              Game Details
            </h2>
            <p className="text-[#8F9BB3]">Full analysis and predictions</p>
          </div>
          <button
            onClick={onClose}
            className="w-10 h-10 rounded-full bg-[#1A1A23] text-[#8F9BB3] hover:text-white hover:bg-[#2A2A35] transition-all duration-200"
          >
            ✕
          </button>
        </div>

        {/* Prediction Box */}
        <div className="mb-8 bg-gradient-to-r from-[#1A1A23] to-[#13131A] rounded-2xl p-6 border border-[#2E3449]">
          <div className="flex items-center gap-3 mb-6">
            <div className="w-12 h-12 rounded-2xl bg-[#00F6FF]/10 flex items-center justify-center">
              <svg className="w-6 h-6 text-[#00F6FF]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
            <div>
              <h3 className="text-2xl font-bold text-white">Game Prediction</h3>
              <p className="text-[#8F9BB3]">AI-powered analysis</p>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div className="bg-[#13131A] rounded-xl p-4">
              <span className="text-[#8F9BB3] text-sm">Predicted Winner</span>
              <div className="text-xl font-bold text-white mt-1">{game.prediction.winner}</div>
            </div>
            <div className="bg-[#13131A] rounded-xl p-4">
              <span className="text-[#8F9BB3] text-sm">Win Probability</span>
              <div className="text-xl font-bold text-[#00F6FF] mt-1">
                {formatPercentage(game.prediction.win_probability)}
              </div>
            </div>
            <div className="bg-[#13131A] rounded-xl p-4">
              <span className="text-[#8F9BB3] text-sm">Spread Range</span>
              <div className="text-xl font-bold text-[#4ADE80] mt-1">
                {game.prediction.spread_confidence_interval.low.toFixed(1)} to {game.prediction.spread_confidence_interval.high.toFixed(1)}
              </div>
            </div>
          </div>
        </div>

        {/* Team Lineups */}
        <div className="grid grid-cols-2 gap-8">
          <TeamLineup team={game.teams.home} isHome={true} />
          <TeamLineup team={game.teams.away} isHome={false} />
        </div>
      </div>
    </div>
  );
};

export default NBAGameModal;