// BetTracker.tsx
import { useState } from 'react';
import { Bet } from '../../types/betting';
import BetTrackerAnalytics from './BetTrackerAnalytics';
import BetsOverview from './BetsOverview';

interface BetTrackerProps {
  bets: Bet[];
  loading: boolean;
  error: string | null;
}

const BetTracker = ({ bets, loading, error }: BetTrackerProps) => {
  const [selectedBookmaker, setSelectedBookmaker] = useState<string | null>(null);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#00F6FF]"></div>
        <div className="ml-3 text-[#00F6FF] text-lg">Loading your bets...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-2xl bg-[rgba(255,61,113,0.15)] border border-[rgba(255,255,255,0.05)] p-4 text-[#FF3D71]">
        {error}
      </div>
    );
  }

  const filteredBets = selectedBookmaker
    ? bets.filter(bet => bet.bookmaker?.name === selectedBookmaker)
    : bets;

  return (
    <div className="space-y-6">
      {/* Header */}
      <div className="flex items-center gap-2 text-sm mb-6">
        <span className="text-[#8F9BB3]">Dashboard /</span>
        <span className="text-white font-medium">Bet Tracker</span>
      </div>
      
      {/* Main Content */}
      <div className="space-y-6">
        <BetTrackerAnalytics 
          bets={filteredBets} 
          onBookmakerSelect={setSelectedBookmaker}
          selectedBookmaker={selectedBookmaker}
        />
        <BetsOverview 
          bets={filteredBets}
          selectedBookmaker={selectedBookmaker}
          onBookmakerSelect={setSelectedBookmaker}
        />
      </div>
    </div>
  );
};

export default BetTracker;