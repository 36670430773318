type OddsFormat = 'american' | 'decimal' | 'fractional';

export const getDefaultOddsFormat = (countryCode: string): OddsFormat => {
  if (countryCode.startsWith('US')) return 'american';
  if (countryCode.startsWith('GB')) return 'fractional';
  return 'decimal'; // EU and others
};

const toDecimal = (americanOdds: number): number => {
  if (americanOdds > 0) {
    return (americanOdds / 100) + 1;
  } else {
    return (-100 / americanOdds) + 1;
  }
};

const toFractional = (americanOdds: number): string => {
  const decimal = toDecimal(americanOdds);
  const fractional = decimal - 1;
  
  // Common fractions for better readability
  const commonFractions: Record<number, string> = {
    0.5: '1/2',
    0.33: '1/3',
    0.67: '2/3',
    0.75: '3/4',
    1: '1/1',
    1.5: '3/2',
    2: '2/1',
    2.5: '5/2',
    3: '3/1',
    4: '4/1',
    5: '5/1',
    6: '6/1'
  };

  // Find closest common fraction
  const closest = Object.entries(commonFractions).reduce((prev, curr) => {
    return Math.abs(parseFloat(curr[0]) - fractional) < Math.abs(parseFloat(prev[0]) - fractional)
      ? curr
      : prev;
  });

  return closest[1];
};

export const convertOdds = (americanOdds: number, countryCode: string): string => {
  const format = getDefaultOddsFormat(countryCode);
  
  switch (format) {
    case 'american':
      return americanOdds.toString();
    
    case 'decimal':
      return toDecimal(americanOdds).toFixed(2);
    
    case 'fractional':
      return toFractional(americanOdds);
    
    default:
      return americanOdds.toString();
  }
};