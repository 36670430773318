import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlass,
  faUser,
  faRightFromBracket,
  faUserPen,
  faMoneyBill,
  faShieldHalved,
} from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import NotificationBell from '../../components/notifications/NotificationBell';
import { supabase } from '../../lib/supabase/client';

interface NavBarProps {
  style?: React.CSSProperties;
}

const NavBar: React.FC<NavBarProps> = ({ style }) => {
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const profileRef = useRef<HTMLDivElement | null>(null);
  const { signOut, user } = useAuthContext();
  const { profile } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchActive(false);
      }
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const loadAvatarUrl = async () => {
      if (!profile?.avatar) {
        setAvatarUrl(null);
        return;
      }

      try {
        const { data, error } = await supabase.storage
          .from('avatars')
          .createSignedUrl(profile.avatar, 3600);

        if (error) throw error;
        setAvatarUrl(data.signedUrl);
      } catch (error) {
        console.error('Error loading avatar:', error);
        setAvatarUrl(null);
      }
    };

    loadAvatarUrl();
  }, [profile?.avatar]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }

    const fetchSearchResults = async () => {
      try {
        const response = await fetch(`/api/search?query=${encodeURIComponent(searchQuery)}`);
        const data = await response.json();
        setSearchResults(data.results || []);
      } catch (error) {
        console.error('Error fetching search results:', error);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const renderProfileImage = () => (
    <div 
      onClick={() => setIsProfileOpen(!isProfileOpen)}
      className="h-9 w-9 rounded-xl overflow-hidden bg-[#13131A] flex items-center justify-center cursor-pointer transition-all duration-200 hover:ring-2 hover:ring-[#00F6FF]/15"
    >
      {avatarUrl ? (
        <img
          src={avatarUrl}
          alt="Profile"
          className="h-full w-full object-cover"
          onError={() => setAvatarUrl(null)}
        />
      ) : (
        <FontAwesomeIcon icon={faUser} className="w-5 h-5 text-[#8F9BB3]" />
      )}
    </div>
  );

  return (
    <div
      className="fixed top-0 right-0 left-20 [aside[data-expanded=true]_&]:left-64 transition-all duration-300 h-16 flex items-center justify-between px-4 bg-[rgba(255,255,255,0.03)] backdrop-blur-sm z-40"
      style={style}
    >
      <div className="flex-1 max-w-2xl" ref={searchRef}>
        <div className="relative bg-[#13131A] rounded-xl">
          {isSearchActive ? (
            <div className="relative">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="absolute left-3 top-1/2 -translate-y-1/2 text-[#8F9BB3] w-5 h-5"
              />
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Games, Teams, or Players"
                className="w-full pl-10 pr-4 py-2.5 bg-transparent text-white rounded-xl outline-none focus:ring-2 focus:ring-[#00F6FF]/15"
                autoFocus
              />
            </div>
          ) : (
            <button
              onClick={() => setIsSearchActive(true)}
              className="w-full flex items-center space-x-2 text-[#8F9BB3] hover:text-white transition-colors px-3 py-2.5"
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} className="w-5 h-5" />
              <span className="text-sm">Search Games, Teams, or Players</span>
            </button>
          )}
        </div>
      </div>

      <div className="flex items-center gap-6">
        <NotificationBell />
        <div className="relative" ref={profileRef}>
          {renderProfileImage()}
          {isProfileOpen && (
            <div className="absolute right-0 mt-2 w-48 rounded-xl shadow-lg bg-[#13131A] ring-1 ring-[rgba(255,255,255,0.05)] py-1 z-50">
              <div className="px-4 py-2 text-sm text-[#8F9BB3] border-b border-[rgba(255,255,255,0.05)]">
                {profile?.username || user?.email}
              </div>
              <button
                onClick={() => navigate('/dashboard/profile')}
                className="w-full flex items-center px-4 py-2 text-sm text-[#8F9BB3] hover:text-[#00F6FF] hover:bg-[rgba(255,255,255,0.03)]"
              >
                <FontAwesomeIcon icon={faUserPen} className="w-4 h-4 mr-2" />
                Edit Profile
              </button>
              <button
                onClick={() => navigate('/dashboard/bookmakers')}
                className="w-full flex items-center px-4 py-2 text-sm text-[#8F9BB3] hover:text-[#00F6FF] hover:bg-[rgba(255,255,255,0.03)]"
              >
                <FontAwesomeIcon icon={faMoneyBill} className="w-4 h-4 mr-2" />
                Bookmakers
              </button>
              <button
                onClick={() => navigate('/dashboard/subscriptions')}
                className="w-full flex items-center px-4 py-2 text-sm text-[#8F9BB3] hover:text-[#00F6FF] hover:bg-[rgba(255,255,255,0.03)]"
              >
                <FontAwesomeIcon icon={faShieldHalved} className="w-4 h-4 mr-2" />
                Subscriptions
              </button>
              <button
                onClick={handleLogout}
                className="w-full flex items-center px-4 py-2 text-sm text-[#8F9BB3] hover:text-[#00F6FF] hover:bg-[rgba(255,255,255,0.03)]"
              >
                <FontAwesomeIcon icon={faRightFromBracket} className="w-4 h-4 mr-2" />
                Logout
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavBar;