import { supabase } from '../lib/supabase/client';

export interface Bookmaker {
  id: string;
  name: string;
  country?: string | null;
  is_active: boolean;
  location: BookmakerLocation;
  autobet: boolean;
  created_at?: string;
  updated_at?: string;
}

export type BookmakerLocation = 'european' | 'american';

export interface BookmakerWithOdds extends Bookmaker {
  odds: number;
}

interface SelectedSportsbook {
  selected: boolean;
  selected_at: string;
  autobet_settings?: {
    minOdds: string;
    maxOdds: string;
    stakeAmount: string;
  };
}

const mapOddsFormat = (format: 'european' | 'american'): BookmakerLocation => {
  return format;
};

export const getBookmakersByLocation = async (format: 'european' | 'american'): Promise<BookmakerWithOdds[]> => {
  try {
    const location = mapOddsFormat(format);
    console.log('Fetching bookmakers for location:', location);

    const { data: bookmakers, error } = await supabase
      .from('bookmakers')
      .select('id, name, country, is_active, location, autobet')
      .eq('is_active', true)
      .eq('location', location)
      .order('name');

    if (error) {
      console.error('Supabase error fetching bookmakers:', error);
      throw error;
    }

    if (!bookmakers || bookmakers.length === 0) {
      console.log('No bookmakers found for location:', location);
      return [];
    }

    // Add temporary odds to bookmakers
    const bookiesWithOdds = bookmakers.map(bookmaker => ({
      ...bookmaker,
      country: bookmaker.country || undefined,
      odds: format === 'european' ? 1.91 : -110
    }));

    return bookiesWithOdds;

  } catch (error) {
    console.error('Error in getBookmakersByLocation:', error);
    return [];
  }
};

/**
 * Fetches a single bookmaker by ID
 */
export const getBookmakerById = async (id: string): Promise<Bookmaker | null> => {
  try {
    console.log('Fetching bookmaker by ID:', id);
    
    const { data, error } = await supabase
      .from('bookmakers')
      .select('id, name, country, is_active, location, autobet')
      .eq('id', id)
      .single();

    if (error) {
      console.error('Error fetching bookmaker by ID:', error);
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in getBookmakerById:', error);
    return null;
  }
};

/**
 * Fetches all active bookmakers
 */
export const getAllActiveBookmakers = async (): Promise<Bookmaker[]> => {
  try {
    console.log('Fetching all active bookmakers...');
    
    const { data, error } = await supabase
      .from('bookmakers')
      .select('id, name, country, is_active, location, autobet')
      .eq('is_active', true)
      .order('name');

    if (error) {
      console.error('Error fetching active bookmakers:', error);
      throw error;
    }

    // Convert null autobet values to false
    const processedData = data?.map(bookie => ({
      ...bookie,
      autobet: !!bookie.autobet // Convert null to false
    })) || [];

    return processedData;
  } catch (error) {
    console.error('Error in getAllActiveBookmakers:', error);
    return [];
  }
};

export const getUserBookmakers = async (userId: string): Promise<Bookmaker[]> => {
  try {
    console.log('Fetching bookmakers for user:', userId);
    
    // First, get the user's selected sportsbooks
    const { data: userData, error: userError } = await supabase
      .from('users')
      .select('selected_sportsbooks')
      .eq('id', userId)
      .single();

    if (userError) {
      console.error('Error fetching user data:', userError);
      throw userError;
    }

    if (!userData?.selected_sportsbooks) {
      console.log('No selected sportsbooks found for user');
      return [];
    }

    // Get the selected bookmaker IDs
    const selectedSportsbooks = userData.selected_sportsbooks as Record<string, SelectedSportsbook>;
    const selectedBookmakerIds = Object.entries(selectedSportsbooks)
      .filter(([_, value]) => value.selected)
      .map(([id]) => id);

    if (selectedBookmakerIds.length === 0) {
      console.log('No selected bookmaker IDs found');
      return [];
    }

    console.log('Selected bookmaker IDs:', selectedBookmakerIds);

    // Fetch the active bookmakers that match the selected IDs
    const { data: bookmakers, error: bookmakerError } = await supabase
      .from('bookmakers')
      .select('id, name, country, is_active, location, autobet')
      .in('id', selectedBookmakerIds)
      .eq('is_active', true);

    if (bookmakerError) {
      console.error('Error fetching bookmakers:', bookmakerError);
      return [];
    }

    // Convert null autobet values to false and return
    const processedBookmakers = bookmakers?.map(bookie => ({
      ...bookie,
      autobet: !!bookie.autobet
    })) || [];

    console.log('Found bookmakers:', processedBookmakers);
    return processedBookmakers;

  } catch (error) {
    console.error('Error in getUserBookmakers:', error);
    return [];
  }
};