// contexts/OddsLocationContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import LocationService from '../utils/locationService';
import { getDefaultOddsFormat } from '../utils/oddsConverter';
import { getCurrencyConfig } from '../utils/currencyFormatter';
import type { OddsFormat } from '../types/odds';

interface OddsLocationContextType {
  countryCode: string;
  oddsFormat: OddsFormat;
  setOddsFormat: (format: OddsFormat) => void;
  currencySymbol: string;
  formatCurrency: (amount: number) => string;
}

const OddsLocationContext = createContext<OddsLocationContextType | undefined>(undefined);

export const OddsLocationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [countryCode, setCountryCode] = useState('US');
  const [oddsFormat, setOddsFormat] = useState<OddsFormat>('american');
  const [currencyConfig, setCurrencyConfig] = useState(getCurrencyConfig('US'));

  useEffect(() => {
    const initLocation = async () => {
      const code = await LocationService.getCountryCode();
      setCountryCode(code);
      setOddsFormat(getDefaultOddsFormat(code));
      setCurrencyConfig(getCurrencyConfig(code));
    };

    initLocation();
  }, []);

  const formatCurrency = (amount: number) => {
    const formattedAmount = Math.abs(amount).toFixed(2);
    if (currencyConfig.position === 'before') {
      return `${currencyConfig.symbol}${formattedAmount}`;
    }
    return `${formattedAmount}${currencyConfig.symbol}`;
  };

  return (
    <OddsLocationContext.Provider 
      value={{ 
        countryCode, 
        oddsFormat, 
        setOddsFormat,
        currencySymbol: currencyConfig.symbol,
        formatCurrency
      }}
    >
      {children}
    </OddsLocationContext.Provider>
  );
};

export const useOddsLocation = () => {
  const context = useContext(OddsLocationContext);
  if (context === undefined) {
    throw new Error('useOddsLocation must be used within an OddsLocationProvider');
  }
  return context;
};