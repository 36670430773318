import { useState, useMemo } from 'react';
import { Bet } from '../../types/betting';
import { useOddsLocation } from '@/contexts/OddsLocationContext';
import { convertOdds } from '@/utils/oddsConverter';

interface BetsOverviewProps {
  bets: Bet[];
  selectedBookmaker: string | null;
  onBookmakerSelect: (bookmaker: string | null) => void;
}

const BetsOverview = ({ bets, selectedBookmaker, onBookmakerSelect }: BetsOverviewProps) => {
  const [sportFilter, setSportFilter] = useState<string>('all');
  const [leagueFilter, setLeagueFilter] = useState<string>('all');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [bookmakerFilter, setBookmakerFilter] = useState<string>('all');
  const [expandedParlays, setExpandedParlays] = useState<Set<string>>(new Set());
  const { oddsFormat, formatCurrency } = useOddsLocation();

  const formatOdds = (odds: string) => {
    if (!odds) return '-';
    const oddsNumber = parseInt(odds);
    if (isNaN(oddsNumber)) return odds;
    return convertOdds(oddsNumber, oddsFormat);
  };

  const toggleParlay = (betId: string) => {
    setExpandedParlays(prev => {
      const newSet = new Set(prev);
      if (newSet.has(betId)) {
        newSet.delete(betId);
      } else {
        newSet.add(betId);
      }
      return newSet;
    });
  };

  const { filteredBets, sports, leagues, statuses, bookmakers } = useMemo(() => {
    const sportsSet = new Set<string>();
    const leaguesSet = new Set<string>();
    const statusesSet = new Set<string>();
    const bookmakersSet = new Set<string>();

    bets.forEach(bet => {
      if (bet.bookmaker?.name) bookmakersSet.add(bet.bookmaker.name);
      if (Array.isArray(bet.legs)) {
        bet.legs.forEach(leg => {
          if (leg.sport?.name) sportsSet.add(leg.sport.name);
          if (leg.league?.name) leaguesSet.add(leg.league.name);
          if (leg.bet_status?.name) statusesSet.add(leg.bet_status.name);
        });
      }
    });

    const filtered = bets.filter(bet => {
      const matchesSport = sportFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.sport?.name === sportFilter));
      const matchesLeague = leagueFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.league?.name === leagueFilter));
      const matchesStatus = statusFilter === 'all' || 
        (Array.isArray(bet.legs) && bet.legs.some(leg => leg.bet_status?.name === statusFilter));
      const matchesBookmaker = bookmakerFilter === 'all' || bet.bookmaker?.name === bookmakerFilter;
      
      return matchesSport && matchesLeague && matchesStatus && matchesBookmaker;
    });

    return {
      filteredBets: filtered,
      sports: Array.from(sportsSet).sort(),
      leagues: Array.from(leaguesSet).sort(),
      statuses: Array.from(statusesSet).sort(),
      bookmakers: Array.from(bookmakersSet).sort()
    };
  }, [bets, sportFilter, leagueFilter, statusFilter, bookmakerFilter]);

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Won':
        return 'bg-[rgba(0,255,0,0.15)] text-[#00FF00]';
      case 'Pending':
        return 'bg-[rgba(0,246,255,0.15)] text-[#00F6FF]';
      case 'Loss':
        return 'bg-[rgba(255,0,0,0.15)] text-[#FF0000]';
      case 'Void':
        return 'bg-[rgba(255,212,38,0.15)] text-[#FFD426]';
      case 'Cashout':
        return 'bg-[rgba(149,149,255,0.15)] text-[#9595FF]';
      default:
        return 'bg-[rgba(0,246,255,0.15)] text-[#00F6FF]';
    }
  };

  const calculatePL = (bet: Bet) => {
    if (!bet.bet_status || bet.bet_status.name === 'Pending') {
      return formatCurrency(0);
    }

    // For single bets
    if (bet.legs.length === 1) {
      const leg = bet.legs[0];
      if (leg.bet_status?.name === 'Won') {
        // Calculate actual profit: (stake × odds) - stake
        const odds = parseFloat(leg.odds || bet.odds);
        const potentialPayout = bet.stake * odds;
        return formatCurrency(potentialPayout - bet.stake);
      } else if (leg.bet_status?.name === 'Loss') {
        return formatCurrency(-bet.stake);
      }
    }

    // For parlay bets
    const parlayStatus = getParlayStatus(bet);
    if (parlayStatus === 'Won') {
      const odds = parseFloat(bet.odds);
      const potentialPayout = bet.stake * odds;
      return formatCurrency(potentialPayout - bet.stake);
    } else if (parlayStatus === 'Loss') {
      return formatCurrency(-bet.stake);
    }

    return formatCurrency(0);
  };
  
  const getParlayStatus = (bet: Bet): string => {
    // If any leg is a loss, entire parlay is lost
    if (bet.legs.some(leg => leg.bet_status?.name === 'Loss')) {
      return 'Loss';
    }
    
    // If all legs are won, parlay is won
    if (bet.legs.every(leg => leg.bet_status?.name === 'Won')) {
      return 'Won';
    }
    
    // If any leg is pending, parlay is pending
    if (bet.legs.some(leg => leg.bet_status?.name === 'Pending')) {
      return 'Pending';
    }
  
    // Default to bet's overall status
    return bet.bet_status?.name || 'Pending';
  };

  const renderSingleRow = (bet: Bet) => {
    const leg = bet.legs[0];
    const odds = leg?.odds || bet.odds || '-';
    
    return (
      <tr key={bet.id} className="hover:bg-[#13131A] transition-all duration-200">
        <td className="p-4 text-[#8F9BB3]">
          <div className="flex flex-col">
            <div>Single</div>
            <div className="text-sm opacity-75">{leg?.leg_type?.name || 'N/A'}</div>
          </div>
        </td>
        <td className="p-4 text-[#8F9BB3]">
          {new Date(bet.placed_at).toLocaleDateString()}
        </td>
        <td className="p-4 text-[#8F9BB3]">{leg?.sport?.name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.league?.name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.event_name || 'N/A'}</td>
        <td className="p-4 text-[#8F9BB3]">{leg?.selection || 'N/A'}</td>
        <td className="p-4 text-right text-[#8F9BB3]">
          {formatCurrency(bet.stake)}
        </td>
        <td className="p-4 text-right text-[#8F9BB3]">{odds}</td>
        <td className="p-4 text-right text-[#8F9BB3]">
          {calculatePL(bet)}
        </td>
        <td className="p-4 text-center">
          <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(leg?.bet_status?.name || '')}`}>
            {leg?.bet_status?.name || 'N/A'}
          </span>
        </td>
      </tr>
    );
  };

  const renderParlayRow = (bet: Bet) => {
    const isExpanded = expandedParlays.has(bet.id);
    const parlayStatus = getParlayStatus(bet);
    
    return (
      <>
        <tr key={bet.id} className="hover:bg-[#13131A] transition-all duration-200">
          <td className="p-4 text-[#8F9BB3]">
            <div className="flex items-center gap-2">
              <span>Parlay ({bet.legs.length} Legs)</span>
              <button
                onClick={() => toggleParlay(bet.id)}
                className="text-[#00F6FF] hover:text-[#8F9BB3] transition-colors"
              >
                {isExpanded ? '▼' : '▶'}
              </button>
            </div>
          </td>
          <td className="p-4 text-[#8F9BB3]">
            {new Date(bet.placed_at).toLocaleDateString()}
          </td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-[#8F9BB3]"></td>
          <td className="p-4 text-right text-[#8F9BB3]">
            {formatCurrency(bet.stake)}
          </td>
          <td className="p-4 text-right text-[#8F9BB3]">{bet.odds || '-'}</td>
          <td className="p-4 text-right text-[#8F9BB3]">
            {calculatePL(bet)}
          </td>
          <td className="p-4 text-center">
            <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(parlayStatus)}`}>
              {parlayStatus}
            </span>
          </td>
        </tr>
        
        {isExpanded && bet.legs.map((leg, index) => (
          <tr key={`${bet.id}-${index}`} className="bg-[#13131A]">
            <td className="p-4 text-[#8F9BB3] pl-8">
              <div className="text-sm opacity-75">{leg.leg_type?.name || 'N/A'}</div>
            </td>
            <td className="p-4 text-[#8F9BB3]"></td>
            <td className="p-4 text-[#8F9BB3]">{leg.sport?.name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.league?.name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.event_name || 'N/A'}</td>
            <td className="p-4 text-[#8F9BB3]">{leg.selection || 'N/A'}</td>
            <td className="p-4 text-right text-[#8F9BB3]"></td>
            <td className="p-4 text-right text-[#8F9BB3]">{leg.odds || '-'}</td>
            <td className="p-4 text-right text-[#8F9BB3]"></td>
            <td className="p-4 text-center">
              <span className={`px-4 py-1.5 rounded-lg text-sm font-medium ${getStatusStyle(leg.bet_status?.name || '')}`}>
                {leg.bet_status?.name || 'N/A'}
              </span>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] overflow-hidden">
      <div className="px-6 py-4 border-b border-[rgba(255,255,255,0.05)] flex justify-between items-center">
        <h2 className="bg-gradient-to-r from-[#FFD426] to-[#00F6FF] bg-clip-text text-transparent font-semibold">
          BET HISTORY
          {selectedBookmaker && ` - ${selectedBookmaker}`}
        </h2>
        <div className="flex gap-2">
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:bg-[#2A2A35] transition-colors"
            value={bookmakerFilter}
            onChange={(e) => setBookmakerFilter(e.target.value)}
          >
            <option value="all" className="bg-[#1A1A23] text-white">All Bookmakers</option>
            {bookmakers.map(bookmaker => (
              <option key={`bookmaker-${bookmaker}`} value={bookmaker} className="bg-[#1A1A23] text-white">
                {bookmaker}
              </option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={sportFilter}
            onChange={(e) => setSportFilter(e.target.value)}
          >
            <option value="all">All Sports</option>
            {sports.map(sport => (
              <option key={`sport-${sport}`} value={sport}>{sport}</option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={leagueFilter}
            onChange={(e) => setLeagueFilter(e.target.value)}
          >
            <option value="all">All Leagues</option>
            {leagues.map(league => (
              <option key={`league-${league}`} value={league}>{league}</option>
            ))}
          </select>
          <select 
            className="px-4 py-1.5 rounded-lg bg-[#13131A] text-[#8F9BB3] text-sm hover:text-white transition-colors"
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="all">All Statuses</option>
            {statuses.map(status => (
              <option key={`status-${status}`} value={status}>{status}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="p-4">
        <table className="w-full">
          <thead>
            <tr className="border-b border-[rgba(255,255,255,0.05)]">
              <th className="text-left p-4 text-[#8F9BB3]">Type</th>
              <th className="text-left p-4 text-[#8F9BB3]">Date</th>
              <th className="text-left p-4 text-[#8F9BB3]">Sport</th>
              <th className="text-left p-4 text-[#8F9BB3]">League</th>
              <th className="text-left p-4 text-[#8F9BB3]">Event</th>
              <th className="text-left p-4 text-[#8F9BB3]">Selection</th>
              <th className="text-right p-4 text-[#8F9BB3]">Stake</th>
              <th className="text-right p-4 text-[#8F9BB3]">Odds </th>
              <th className="text-right p-4 text-[#8F9BB3]">P/L</th>
              <th className="text-center p-4 text-[#8F9BB3]">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-[rgba(255,255,255,0.05)]">
            {filteredBets.map((bet) => (
              bet.legs.length > 1 ? renderParlayRow(bet) : renderSingleRow(bet)
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BetsOverview;