import { BrowserRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { UserProvider } from './contexts/UserContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ToastProvider } from './contexts/ToastContext';
import { supabase } from './lib/supabase/client';
import { AppRoutes } from './routes/protected/AppRoutes';
import { ErrorBoundary } from './components/ErrorBoundary';
import { OddsLocationProvider } from './contexts/OddsLocationContext';

const AppContent = () => {
  const { user } = useAuthContext();
  const [refreshKey, setRefreshKey] = useState(0);

  useEffect(() => {
    if (!user?.id) return;

    const channel = supabase.channel('bet-notifications')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${user.id} AND type=eq.bet_update`
        },
        () => {
          setRefreshKey(prev => prev + 1);
        }
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [user?.id]);

  return <AppRoutes key={refreshKey} />;
};

function App() {
  return (
    <OddsLocationProvider>
    <ErrorBoundary>
      <BrowserRouter>
        <AuthProvider supabase={supabase}>
          <UserProvider>
            <ToastProvider>
              <NotificationProvider>
                <AppContent />
              </NotificationProvider>
            </ToastProvider>
          </UserProvider>
        </AuthProvider>
      </BrowserRouter>
    </ErrorBoundary>
    </OddsLocationProvider>
  );
}

export default App;