import { supabase } from '../lib/supabase/client';

// Interface for the database shape
interface DBNotification {
  id: string;
  user_id: string;
  message: string;
  type: 'bet_result' | 'bet_update' | 'system';
  read: boolean;
  created_at: string;
  metadata?: {
    betId?: string;
    status?: 'won' | 'loss' | 'pending';
    url?: string;
    [key: string]: any;
  };
}

// Interface for the application shape
export interface Notification {
  id: string;
  user_id: string;
  message: string;
  type: 'bet_result' | 'bet_update' | 'system';
  read: boolean;
  createdAt: string;
  metadata?: {
    betId?: string;
    status?: 'won' | 'loss' | 'pending';
    url?: string;
    [key: string]: any;
  };
}

class NotificationService {
  private transformNotification(dbNotification: any): Notification {
    // Ensure we have a created_at value
    const created_at = dbNotification.created_at || new Date().toISOString();
    
    return {
      id: dbNotification.id,
      user_id: dbNotification.user_id,
      message: dbNotification.message,
      type: dbNotification.type,
      read: dbNotification.read,
      createdAt: created_at,
      metadata: dbNotification.metadata
    };
  }

  async getNotifications(userId: string): Promise<Notification[]> {
    const { data, error } = await supabase
      .from('notifications')
      .select('*')
      .eq('user_id', userId)
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching notifications:', error);
      throw error;
    }

    return (data || []).map(notification => this.transformNotification(notification));
  }

  async markAsRead(notificationId: string): Promise<void> {
    const { error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('id', notificationId);

    if (error) {
      console.error('Error marking notification as read:', error);
      throw error;
    }
  }

  async markAllAsRead(userId: string): Promise<void> {
    const { error } = await supabase
      .from('notifications')
      .update({ read: true })
      .eq('user_id', userId)
      .eq('read', false);

    if (error) {
      console.error('Error marking all notifications as read:', error);
      throw error;
    }
  }

  async createNotification(notification: Omit<Notification, 'id' | 'createdAt'>): Promise<void> {
    const now = new Date().toISOString();
    const dbNotification = {
      ...notification,
      created_at: now
    };

    const { error } = await supabase
      .from('notifications')
      .insert([dbNotification]);

    if (error) {
      console.error('Error creating notification:', error);
      throw error;
    }
  }

  subscribeToNotifications(userId: string, onNotification: (notification: Notification) => void) {
    return supabase.channel('user-notifications')
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'notifications',
          filter: `user_id=eq.${userId}`
        },
        (payload) => {
          if (payload.new) {
            const notification = this.transformNotification(payload.new);
            onNotification(notification);
          }
        }
      )
      .subscribe();
  }
}

export const notificationService = new NotificationService();