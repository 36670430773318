import { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { Bet } from '../../types/betting';
import { useOddsLocation } from '@/contexts/OddsLocationContext';

interface BetTrackerAnalyticsProps {
  bets: Bet[];
  selectedBookmaker: string | null;
  onBookmakerSelect: (bookmaker: string | null) => void;
}

interface ChartDataPoint {
  date: string;
  profit: number;
}

const BetTrackerAnalytics = ({ bets, selectedBookmaker, onBookmakerSelect }: BetTrackerAnalyticsProps) => {
  const { formatCurrency } = useOddsLocation();

  const bookmakers = useMemo(() => {
    const uniqueBookmakers = new Set<string>();
    bets.forEach(bet => {
      if (bet.bookmaker?.name) {
        uniqueBookmakers.add(bet.bookmaker.name);
      }
    });
    return Array.from(uniqueBookmakers).sort();
  }, [bets]);

  const filteredBets = useMemo(() => {
    if (!selectedBookmaker) return bets;
    return bets.filter(bet => bet.bookmaker?.name === selectedBookmaker);
  }, [bets, selectedBookmaker]);

  const calculateBetProfit = (bet: Bet): number => {
    // First check if bet is settled
    if (!bet.bet_status || bet.bet_status.name === 'Pending') {
      return 0;
    }
  
    // Handle single bets
    if (bet.legs.length === 1) {
      const leg = bet.legs[0];
      if (leg.bet_status?.name === 'Won') {
        const odds = parseFloat(leg.odds || bet.odds);
        return (bet.stake * odds) - bet.stake;
      } else if (leg.bet_status?.name === 'Loss') {
        return -bet.stake;
      }
    }
  
    // Handle parlay bets
    else {
      if (bet.bet_status.name === 'Won') {
        // All legs won - calculate total odds and profit
        const odds = parseFloat(bet.odds);
        return (bet.stake * odds) - bet.stake;
      } else if (bet.bet_status.name === 'Loss') {
        // At least one leg lost - lose full stake
        return -bet.stake;
      }
    }
  
    return 0;
  }

  const stats = useMemo(() => {
    const settledBets = filteredBets.filter(bet => 
      bet.bet_status?.name !== 'Pending' && bet.bet_status?.name !== undefined
    );
    
    const totalBets = settledBets.length;
    const wonBets = settledBets.filter(bet => {
      if (bet.legs.length === 1) {
        return bet.legs[0].bet_status?.name === 'Won';
      }
      return bet.bet_status?.name === 'Won';
    }).length;
    const totalStake = settledBets.reduce((sum, bet) => sum + bet.stake, 0);
    const totalProfit = settledBets.reduce((sum, bet) => sum + calculateBetProfit(bet), 0);

    return {
      totalBets: totalBets.toString(),
      winRate: totalBets ? `${((wonBets / totalBets) * 100).toFixed(1)}%` : '0%',
      profitLoss: totalProfit >= 0 ? `+${formatCurrency(totalProfit)}` : `-${formatCurrency(Math.abs(totalProfit))}`,
      avgStake: formatCurrency(totalBets ? totalStake / totalBets : 0)
    };
  }, [filteredBets, formatCurrency]);

  const chartData = useMemo(() => {
    const profitByDay: Record<string, { profit: number; runningTotal: number }> = {};
    let runningTotal = 0;

    // Sort bets by date
    const sortedBets = [...filteredBets].sort((a, b) => 
      new Date(a.placed_at).getTime() - new Date(b.placed_at).getTime()
    );

    sortedBets.forEach(bet => {
      const date = new Date(bet.placed_at).toLocaleDateString();
      if (!profitByDay[date]) {
        profitByDay[date] = { profit: 0, runningTotal: 0 };
      }
      const profit = calculateBetProfit(bet);
      profitByDay[date].profit += profit;
      runningTotal += profit;
      profitByDay[date].runningTotal = runningTotal;
    });

    return Object.entries(profitByDay).map(([date, data]): ChartDataPoint => ({
      date,
      profit: Number(data.runningTotal.toFixed(2))
    }));
  }, [filteredBets]);

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const profit = payload[0].value;
      return (
        <div className="bg-[#1A1A23] p-3 border border-[rgba(255,255,255,0.1)] rounded-lg">
          <p className="text-[#8F9BB3]">{label}</p>
          <p className={`font-medium ${profit >= 0 ? 'text-[#4ADE80]' : 'text-[#FF3D71]'}`}>
            {profit >= 0 ? '+' : ''}{formatCurrency(profit)}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        <div className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] p-6">
          <select
            value={selectedBookmaker || ''}
            onChange={(e) => onBookmakerSelect(e.target.value || null)}
            className="w-full bg-transparent text-white text-lg font-bold focus:outline-none cursor-pointer hover:bg-[#2A2A35] rounded px-2 py-1 transition-colors"
          >
            <option value="" className="bg-[#1A1A23] text-white">All Bookmakers</option>
            {bookmakers.map(bookmaker => (
              <option key={bookmaker} value={bookmaker} className="bg-[#1A1A23] text-white">
                {bookmaker}
              </option>
            ))}
          </select>
          <p className="text-[#8F9BB3] mt-1">Selected Bookmaker</p>
        </div>
        {Object.entries(stats).map(([key, value]) => (
          <div key={key} className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] p-6">
            <div className={`text-2xl font-bold ${
              key === 'profitLoss' 
                ? value.startsWith('+') 
                  ? 'text-[#4ADE80]'
                  : 'text-[#FF3D71]'
                : 'text-white'
            }`}>
              {key === 'profitLoss' ? value.slice(value.startsWith('+') ? 1 : 0) : value}
            </div>
            <p className="text-[#8F9BB3] mt-1">
              {key === 'profitLoss' ? 'P/L' : key.replace(/([A-Z])/g, ' $1').trim()}
            </p>
          </div>
        ))}
      </div>
      
      <div className="rounded-2xl bg-[rgba(255,255,255,0.03)] backdrop-blur-sm border border-[rgba(255,255,255,0.05)] overflow-hidden">
        <div className="px-6 py-4 border-b border-[rgba(255,255,255,0.05)]">
          <h2 className="bg-gradient-to-r from-[#FFD426] to-[#00F6FF] bg-clip-text text-transparent font-semibold">
            PROFIT/LOSS TRACKER
            {selectedBookmaker && ` - ${selectedBookmaker}`}
          </h2>
        </div>
        <div className="p-6 h-64">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData}>
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255,255,255,0.05)" />
              <XAxis dataKey="date" stroke="#8F9BB3" />
              <YAxis 
                stroke="#8F9BB3"
                tickFormatter={(value) => {
                  const formatted = formatCurrency(value);
                  return value >= 0 ? `+${formatted}` : formatted;
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line 
                type="monotone" 
                dataKey="profit"
                stroke="#00F6FF"
                strokeWidth={2}
                dot={{ fill: '#00F6FF', strokeWidth: 2 }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default BetTrackerAnalytics;