import { useEffect, useState } from 'react';
import { NBAPrediction, NBAPredictionsResponse } from '../../../types/nbaPredictions';
import { fetchNBAPredictions } from '../../../services/nbaPredictionsService';
import { useAuth } from '../../../hooks/useAuth';
import { supabase } from '../../../lib/supabase/client';
import BettingModal from './BettingModal';
import NBAGameModal from './NBAGameModal';
import { useOddsLocation } from '@/contexts/OddsLocationContext';

export interface NBASinglesProps {
  onGameSelect?: (game: NBAPrediction) => void;
}

const getTeamName = (teamName: string): string => {
  const teamAbbreviations: { [key: string]: string } = {
    'LAC': 'clippers',
    'DEN': 'nuggets',
    'SAC': 'kings',
    'SAS': 'spurs',
    'POR': 'trail blazers',
    'DAL': 'mavericks',
    'UTA': 'jazz',
    'LAL': 'lakers',
    'HOU': 'rockets',
    'OKC': 'thunder',
    'NYK': 'knicks',
    'NOP': 'pelicans',
    'BKN': 'nets',
    'ORL': 'magic',
    'TOR': 'raptors',
    'MIA': 'heat',
    'CLE': 'cavaliers',
    'BOS': 'celtics',
    'MEM': 'grizzlies',
    'IND': 'pacers'
  };

  return teamAbbreviations[teamName] || teamName.toLowerCase();
};

export const NBASingles = ({ onGameSelect }: NBASinglesProps) => {
  const [predictions, setPredictions] = useState<NBAPredictionsResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [selectedGame, setSelectedGame] = useState<NBAPrediction | null>(null);
  const [isBettingModalOpen, setIsBettingModalOpen] = useState(false);
  const [isGameModalOpen, setIsGameModalOpen] = useState(false);
  const { user } = useAuth();
  const { oddsFormat } = useOddsLocation();

  useEffect(() => {
    const loadPredictions = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (session?.access_token) {
          const data = await fetchNBAPredictions(session.access_token);
          setPredictions(data);
        }
      } catch (err) {
        setError('Failed to load predictions');
        console.error(err);
      }
    };

    if (user) {
      loadPredictions();
    }
  }, [user]);

  const handleAddBet = (game: NBAPrediction, e: React.MouseEvent) => {
    e.stopPropagation();
    if (game.prediction.winner !== "AI Unsure") {
      setSelectedGame(game);
      setIsBettingModalOpen(true);
    }
  };

  const handleGameClick = (game: NBAPrediction) => {
    console.log('Game data being passed to modal:', game); // Add this line
    if (onGameSelect) {
      onGameSelect(game);
    }
  };

  if (error) {
    return (
      <div className="text-red-500 p-4">
        {error}
      </div>
    );
  }

  if (!predictions) {
    return (
      <div className="text-white p-4">
        Loading predictions...
      </div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {predictions.games.map((game, index) => (
          <div
            key={index}
            className="bg-[#13131A] rounded-xl overflow-hidden cursor-pointer hover:bg-[#1E1E26] transition-colors"
            onClick={() => handleGameClick(game)}
          >
            <div className="p-4 border-b border-[rgba(255,255,255,0.05)]">
              <div className="text-center">
                <h4 className="text-white font-semibold mb-2">{game.prediction.winner}</h4>
                <p className={`text-sm ${
                  game.prediction.win_probability >= 60 ? 'text-green-400' : 'text-[#00F6FF]'
                }`}>
                  {game.prediction.win_probability.toFixed(1)}% Win Probability
                </p>
              </div>
            </div>
            
            <div className="p-4">
              <div className="flex justify-between items-center mb-4">
                <div className="flex items-center gap-2">
                  <img 
                    src={`/assets/img/nba-logos/${getTeamName(game.teams.away.name)}.png`}
                    alt={game.teams.away.name}
                    className="w-8 h-8"
                  />
                  <span className="text-white text-sm">{game.teams.away.name}</span>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-white text-sm">{game.teams.home.name}</span>
                  <img 
                    src={`/assets/img/nba-logos/${getTeamName(game.teams.home.name)}.png`}
                    alt={game.teams.home.name}
                    className="w-8 h-8"
                  />
                </div>
              </div>

              <div className="text-center">
                <p className="text-[#8F9BB3] text-sm mb-1">Recent Form:</p>
                <div className="flex justify-between text-white">
                  <span>{game.recent_form?.away || 'N/A'}</span>
                  <span>{game.recent_form?.home || 'N/A'}</span>
                </div>
              </div>

              <div className="mt-2 text-center">
                <p className="text-[#8F9BB3] text-sm mb-1">Team Health:</p>
                <div className="flex justify-between text-white">
                  <span>{game.team_health?.away?.toFixed(1) || 'N/A'}%</span>
                  <span>{game.team_health?.home?.toFixed(1) || 'N/A'}%</span>
                </div>
              </div>

              <div className="mt-4">
                <button
                  onClick={(e) => handleAddBet(game, e)}
                  className="w-full bg-[#4263EB] text-white py-2 px-4 rounded-lg font-medium hover:bg-[#3651C9] transition-colors"
                  disabled={game.prediction.winner === "AI Unsure"}
                >
                  Add Bet
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedGame && (
        <>
          <BettingModal
            isOpen={isBettingModalOpen}
            onClose={() => {
              setIsBettingModalOpen(false);
              setSelectedGame(null);
            }}
            game={selectedGame}
            eventId={selectedGame.eventid}
            oddsFormat={oddsFormat}
          />

          <NBAGameModal
            isOpen={isGameModalOpen}
            onClose={() => {
              setIsGameModalOpen(false);
              setSelectedGame(null);
            }}
            game={selectedGame}
          />
        </>
      )}
    </>
  );
};

export default NBASingles;