import { useEffect, useState, useCallback } from 'react';
import { useAuthContext } from '../../contexts/AuthContext';
import BetTracker from '../../components/dashboard/BetTracker';
import { supabase } from '../../lib/supabase/client';
import type { Bet } from '../../types/betting';
import { getUserBets } from '../../services/betTrackingService';

// BetTrackerPage.tsx
const BetTrackerPage = () => {
  const { user } = useAuthContext();
  const [bets, setBets] = useState<Bet[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchBets = useCallback(async () => {
    if (!user?.id) return;
    try {
      setLoading(true);
      const userBets = await getUserBets(supabase, user.id);
      setBets(userBets || []);
    } catch (err) {
      console.error('Error fetching bets:', err);
      setError('Failed to load your bets.');
    } finally {
      setLoading(false);
    }
  }, [user?.id]);

  // Listen directly for bet changes
  useEffect(() => {
    if (!user?.id) return;

    fetchBets();

    const channel = supabase.channel('realtime-bets')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'bets',
          filter: `user_id=eq.${user.id}`
        },
        () => fetchBets()
      )
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'bet_legs'
        },
        () => fetchBets()
      )
      .subscribe();

    return () => {
      channel.unsubscribe();
    };
  }, [user?.id, fetchBets]);

  return (
    <div className="space-y-4 max-w-[1440px] mx-auto">
      <BetTracker bets={bets} loading={loading} error={error} />
    </div>
  );
};

export default BetTrackerPage;