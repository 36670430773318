import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign, faXmark } from '@fortawesome/free-solid-svg-icons';
import { createPortal } from 'react-dom';

interface ToastProps {
  message: string;
  type?: 'success' | 'error' | 'info';
  duration?: number;
  onClose: () => void;
}

export const Toast: React.FC<ToastProps> = ({ 
  message, 
  type = 'success', 
  duration = 5000, 
  onClose 
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const toastContent = (
    <div 
      className="fixed top-4 left-1/2 transform -translate-x-1/2 z-[9999] animate-fade-in"
      style={{
        animation: 'slideIn 0.3s ease-out',
        WebkitAnimation: 'slideIn 0.3s ease-out'
      }}
    >
      <div 
        className={`
          flex items-center gap-3 px-6 py-4 rounded-lg shadow-xl min-w-[300px]
          ${type === 'success' ? 'bg-[#1C4532] text-green-400 border border-green-500/20' :
            type === 'error' ? 'bg-[#481A1D] text-red-400 border border-red-500/20' :
            'bg-[#1A365D] text-[#00F6FF] border border-[#00F6FF]/20'}
        `}
        style={{
          backdropFilter: 'blur(8px)',
          WebkitBackdropFilter: 'blur(8px)'
        }}
      >
        <FontAwesomeIcon icon={faDollarSign} className="w-5 h-5" />
        <p className="text-sm font-medium flex-grow">{message}</p>
        <button 
          onClick={onClose}
          className="hover:opacity-80 transition-opacity ml-2"
          aria-label="Close notification"
        >
          <FontAwesomeIcon icon={faXmark} className="w-4 h-4" />
        </button>
      </div>
    </div>
  );

  // Add styles to the document head
  useEffect(() => {
    const style = document.createElement('style');
    style.textContent = `
      @keyframes slideIn {
        from {
          transform: translate(-50%, -100%);
          opacity: 0;
        }
        to {
          transform: translate(-50%, 0);
          opacity: 1;
        }
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return createPortal(toastContent, document.body);
};

interface ToastManagerProps {
  toasts: Array<{
    id: string;
    message: string;
    type?: 'success' | 'error' | 'info';
  }>;
  onDismiss: (id: string) => void;
}

export const ToastManager: React.FC<ToastManagerProps> = ({ toasts, onDismiss }) => {
  return createPortal(
    <div className="fixed top-0 left-0 right-0 z-[9999] pointer-events-none">
      <div className="flex flex-col items-center space-y-2 mt-4 pointer-events-auto">
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            message={toast.message}
            type={toast.type}
            onClose={() => onDismiss(toast.id)}
          />
        ))}
      </div>
    </div>,
    document.body
  );
};
