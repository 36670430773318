interface LocationInfo {
    countryCode: string;
  }
  
  class LocationService {
    private static instance: LocationService;
    private countryCode: string | null = null;
  
    private constructor() {}
  
    static getInstance(): LocationService {
      if (!LocationService.instance) {
        LocationService.instance = new LocationService();
      }
      return LocationService.instance;
    }
  
    async getCountryCode(): Promise<string> {
      if (this.countryCode !== null) return this.countryCode;
  
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data: { country_code: string } = await response.json();
        this.countryCode = data.country_code || 'US';
        return this.countryCode;
      } catch (error) {
        console.error('Error fetching location:', error);
        this.countryCode = 'US'; // Default to US if location fetch fails
        return this.countryCode;
      }
    }
  }
  
  export default LocationService.getInstance();