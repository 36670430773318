interface CurrencyConfig {
    symbol: string;
    position: 'before' | 'after';
    code: string;
  }
  
  const CURRENCY_MAP: Record<string, CurrencyConfig> = {
    // North America
    'US': { symbol: '$', position: 'before', code: 'USD' },
    'CA': { symbol: '$', position: 'before', code: 'CAD' },
    'MX': { symbol: '$', position: 'before', code: 'MXN' },
  
    // South America
    'BR': { symbol: 'R$', position: 'before', code: 'BRL' },
    'AR': { symbol: '$', position: 'before', code: 'ARS' },
    'CL': { symbol: '$', position: 'before', code: 'CLP' },
    'CO': { symbol: '$', position: 'before', code: 'COP' },
    'PE': { symbol: 'S/', position: 'before', code: 'PEN' },
    'UY': { symbol: '$', position: 'before', code: 'UYU' },
  
    // Europe
    'GB': { symbol: '£', position: 'before', code: 'GBP' },
    'DE': { symbol: '€', position: 'after', code: 'EUR' },
    'FR': { symbol: '€', position: 'after', code: 'EUR' },
    'IT': { symbol: '€', position: 'after', code: 'EUR' },
    'ES': { symbol: '€', position: 'after', code: 'EUR' },
    'PT': { symbol: '€', position: 'after', code: 'EUR' },
    'NL': { symbol: '€', position: 'after', code: 'EUR' },
    'BE': { symbol: '€', position: 'after', code: 'EUR' },
    'IE': { symbol: '€', position: 'after', code: 'EUR' },
    'GR': { symbol: '€', position: 'after', code: 'EUR' },
    'AT': { symbol: '€', position: 'after', code: 'EUR' },
    'CH': { symbol: 'CHF', position: 'after', code: 'CHF' },
    'SE': { symbol: 'kr', position: 'after', code: 'SEK' },
    'NO': { symbol: 'kr', position: 'after', code: 'NOK' },
    'DK': { symbol: 'kr', position: 'after', code: 'DKK' },
    'PL': { symbol: 'zł', position: 'after', code: 'PLN' },
    'CZ': { symbol: 'Kč', position: 'after', code: 'CZK' },
    'HU': { symbol: 'Ft', position: 'after', code: 'HUF' },
    'RO': { symbol: 'lei', position: 'after', code: 'RON' },
  
    // Asia
    'JP': { symbol: '¥', position: 'before', code: 'JPY' },
    'CN': { symbol: '¥', position: 'before', code: 'CNY' },
    'KR': { symbol: '₩', position: 'before', code: 'KRW' },
    'HK': { symbol: 'HK$', position: 'before', code: 'HKD' },
    'SG': { symbol: '$', position: 'before', code: 'SGD' },
    'TW': { symbol: 'NT$', position: 'before', code: 'TWD' },
    'TH': { symbol: '฿', position: 'before', code: 'THB' },
    'MY': { symbol: 'RM', position: 'before', code: 'MYR' },
    'ID': { symbol: 'Rp', position: 'before', code: 'IDR' },
    'IN': { symbol: '₹', position: 'before', code: 'INR' },
    'PH': { symbol: '₱', position: 'before', code: 'PHP' },
    'VN': { symbol: '₫', position: 'after', code: 'VND' },
  
    // Oceania
    'AU': { symbol: '$', position: 'before', code: 'AUD' },
    'NZ': { symbol: '$', position: 'before', code: 'NZD' },
  
    // Middle East
    'IL': { symbol: '₪', position: 'before', code: 'ILS' },
    'SA': { symbol: 'ر.س', position: 'before', code: 'SAR' },
    'AE': { symbol: 'د.إ', position: 'before', code: 'AED' },
    'TR': { symbol: '₺', position: 'before', code: 'TRY' },
  
    // Africa
    'ZA': { symbol: 'R', position: 'before', code: 'ZAR' },
    'EG': { symbol: 'E£', position: 'before', code: 'EGP' },
    'NG': { symbol: '₦', position: 'before', code: 'NGN' },
    'KE': { symbol: 'KSh', position: 'before', code: 'KES' },
    'MA': { symbol: 'د.م.', position: 'before', code: 'MAD' },
  
    // Crypto (for future use)
    'BTC': { symbol: '₿', position: 'before', code: 'BTC' },
    'ETH': { symbol: 'Ξ', position: 'before', code: 'ETH' }
  };
  
  export const getCurrencyConfig = (countryCode: string): CurrencyConfig => {
    // Handle Euro zone countries
    const euroZoneCountries = ['DE', 'FR', 'IT', 'ES', 'PT', 'NL', 'BE', 'IE', 'GR', 'AT', 'FI', 'SK', 'SI', 'LT', 'LV', 'EE', 'CY', 'MT', 'LU'];
    if (euroZoneCountries.includes(countryCode)) {
      return CURRENCY_MAP['DE']; // Use German Euro format as default for Euro
    }
  
    // Return country-specific currency or default to USD
    return CURRENCY_MAP[countryCode] || CURRENCY_MAP['US'];
  };
  
  export const formatCurrency = (amount: number, countryCode: string): string => {
    const config = getCurrencyConfig(countryCode);
    const formattedAmount = Math.abs(amount).toFixed(2);
    
    // Special formatting for some currencies
    let numberWithSeparators = formattedAmount;
    if (['JPY', 'KRW', 'VND'].includes(config.code)) {
      // No decimal places for these currencies
      numberWithSeparators = Math.round(Math.abs(amount)).toString();
    }
    
    // Add thousand separators
    numberWithSeparators = numberWithSeparators.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
    if (config.position === 'before') {
      return `${amount >= 0 ? '+' : '-'}${config.symbol}${numberWithSeparators}`;
    } else {
      return `${amount >= 0 ? '+' : '-'}${numberWithSeparators}${config.symbol}`;
    }
  };
  
  export const getCurrencySymbol = (countryCode: string): string => {
    const config = getCurrencyConfig(countryCode);
    return config.symbol;
  };
  
  export const getCurrencyCode = (countryCode: string): string => {
    const config = getCurrencyConfig(countryCode);
    return config.code;
  };